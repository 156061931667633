import React from "react";
import "./App.css";
import {
  withStyles,
  Theme,
  createStyles,
  WithStyles,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { WithTheme, MuiThemeProvider } from "@material-ui/core";
import { Route, HashRouter, Redirect, Switch } from "react-router-dom";
import Login from "./pages/Login";
import Splash from "./pages/Splash";
import Results from "./pages/Results";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Membership from "./pages/Membership";
import NewMembers from "./pages/NewMembers";
import WhatsApp from "./pages/WhatsApp";
import Setup from "./pages/Setup";
import Photos from "./pages/Photos";
import Logout from "./pages/Logout";
import { useLocalStorageState } from "./hooks/localStorageHook";
import { ClearLocalStorage } from "./pages/ClearLocalStorage";
import Constitution from "./pages/Constitution";

interface AppWrapperProps extends WithStyles<typeof styles>, WithTheme {}

const styles = createStyles({});

const AppWrapperWithoutStyles = ({ classes, theme }: AppWrapperProps) => {
  const [hasBeenHereBefore, setHasBeenHereBefore] =
    useLocalStorageState<boolean>("hasBeenHereBefore", false);

  const redirect: React.ReactNode = hasBeenHereBefore ? (
    <Redirect exact path="/" to="/home" />
  ) : (
    <Redirect exact path="/" to="/intro" />
  );

  return (
    <React.Fragment>
      <MuiThemeProvider theme={theme}>
        <div>
          <CssBaseline />
          <HashRouter>
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/intro" component={Splash} />
              <Route path="/home" component={Home} />
              <Route path="/results" component={Results} />
              <Route path="/photos" component={Photos} />
              <Route path="/setup" component={Setup} />
              <Route path="/membership" component={Membership} />
              <Route path="/new_members" component={NewMembers} />
              <Route path="/whatsapp" component={WhatsApp} />
              <Route path="/*rules" component={Constitution} />
              <Route path="/constitution" component={Constitution} />
              <Route path="/contact" component={Contact} />
              <Route path="/logout" component={Logout} />
              <Route path="/clear" component={ClearLocalStorage} />
              {redirect}
            </Switch>
          </HashRouter>
        </div>
      </MuiThemeProvider>
    </React.Fragment>
  );
};

const App = withStyles(styles)(AppWrapperWithoutStyles);

export default App;
