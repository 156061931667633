import React from "react";
import Layout from "../components/Layout";
import PageTitle from "../components/PageTitle";
import {
  createStyles,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { rules } from "../data/constitution_and_rules";
import Card from "../components/Card";

interface ConstitutionProps extends WithStyles<typeof styles> {}

const Constitution = (props: ConstitutionProps) => {
  const the_rules = rules.map((rule, i) => (
    <Typography>
      {i + 1}. {rule}
      <br />
      <br />
    </Typography>
  ));
  return (
    <Layout>
      <PageTitle text="Constitution and Rules" />
      <Typography>
        <i>November 1986</i>
        <br />
        <br />
      </Typography>

      <Card withPadding={true}>{the_rules}</Card>
    </Layout>
  );
};

const styles = createStyles({});

export default withStyles(styles)(Constitution);
